import _slicedToArray from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _extends from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var __jsx = React.createElement;
import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
var useStyles = makeStyles(function (theme) {
  return {
    modalTextHeader: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '40px',
      fontFamily: 'Open Sans, sans-serif',
      textAlign: 'center',
      color: '#525252',
      marginTop: 10,
      '@media only screen and (max-width: 425px)': {
        fontSize: 26,
        lineHeight: '20px'
      }
    },
    modalIconHeader: {
      fontSize: 25,
      marginRight: 5,
      position: 'relative',
      top: -2,
      '@media only screen and (max-width: 425px)': {
        fontSize: 20
      }
    },
    modalText: {
      marginBottom: 30,
      fontSize: 16,
      lineHeight: '28px',
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: 400,
      maxWidth: 608,
      color: '#525252',
      textAlign: 'justify'
    },
    modalContentBox: {
      padding: '0 20px',
      '@media only screen and (max-width: 425px)': {
        padding: '0'
      }
    },
    modalHeader: {
      fontSize: 18,
      color: '#525252',
      padding: '0 20px',
      fontWeight: 500,
      textAlign: 'center'
    }
  };
});

var styles = function styles(theme) {
  return {
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  };
};

var DialogTitle = withStyles(styles)(function (props) {
  var children = props.children,
      classes = props.classes,
      onClose = props.onClose,
      other = _objectWithoutProperties(props, ["children", "classes", "onClose"]);

  return __jsx(MuiDialogTitle, _extends({
    disableTypography: true,
    className: classes.root
  }, other), __jsx(Typography, {
    variant: "h6"
  }, children), onClose ? __jsx(IconButton, {
    "aria-label": "close",
    className: classes.closeButton,
    onClick: onClose
  }, __jsx(CloseIcon, null)) : null);
});
var DialogContent = withStyles(function (theme) {
  return {
    root: {
      padding: theme.spacing(2)
    }
  };
})(MuiDialogContent);

var ComingSoon = function ComingSoon(_ref) {
  var forwardRef = _ref.forwardRef,
      onClose = _ref.onClose;
  var classes = useStyles();
  var comingSoonRef = React.useRef(null);

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      openComingSoon = _React$useState2[0],
      setOpenComingSoon = _React$useState2[1];

  React.useImperativeHandle(forwardRef, function () {
    return {
      show: function show() {
        setOpenComingSoon(true);
      },
      hide: function hide() {
        setOpenComingSoon(false);
      }
    };
  });

  var handleCloseComingSoon = function handleCloseComingSoon() {
    setOpenComingSoon(false);
    onClose();
  };

  return __jsx(Dialog, {
    ref: comingSoonRef,
    onClose: handleCloseComingSoon,
    "aria-labelledby": "customized-dialog-title",
    open: openComingSoon
  }, __jsx(DialogTitle, {
    onClose: handleCloseComingSoon
  }, __jsx(Box, {
    className: classes.modalTextHeader
  }, __jsx("span", {
    role: "img",
    "aria-label": "Gear",
    className: classes.modalIconHeader
  }, "\u2699\uFE0F"), __jsx("span", null, "Coming Soon"))), __jsx(DialogContent, null, __jsx(Box, {
    className: classes.modalContentBox
  }, __jsx(Typography, {
    gutterBottom: true,
    className: classes.modalText
  }, __jsx("span", null, "Please don't be mad. We are working hard to fix this, the template will be available very soon!")), __jsx(Typography, {
    gutterBottom: true,
    className: classes.modalText
  }, __jsx("span", null, "Here's a joke to make you smile in the meantime:"), __jsx("br", null), __jsx("span", {
    style: {
      fontWeight: 600
    }
  }, "What\u2019s the best thing about Switzerland?"), __jsx("br", null), __jsx("em", null, "I don\u2019t know, but the flag is a big plus."), __jsx("span", {
    role: "img",
    "aria-label": "smile"
  }, ' ', "\uD83D\uDE0A")))));
};

ComingSoon.defaultProps = {
  forwardRef: {},
  onClose: function onClose() {}
};
export default ComingSoon;