import _slicedToArray from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var __jsx = React.createElement;
import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import Loader from '../Loader';
import ActionAlert from '../Alert';
import RemoteAutoComplete from '../RemoteAutoComplete';
import { types } from '../../services/entity.services';
import { setProfile } from '../../services/security.service';
import translate from '../../data/translations';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

var ListSkeleton = function ListSkeleton() {
  var classes = useStyles();
  var items = [1, 2, 3, 4, 5, 6, 7, 8];
  return __jsx(Grid, {
    container: true,
    className: classes.gridContainer
  }, items.map(function (item) {
    return __jsx(Grid, {
      key: item,
      item: true,
      xs: 6,
      sm: 6,
      lg: 6,
      xl: 6
    }, __jsx(Box, {
      className: clsx(classes.item, 'loader')
    }, "Loading..."));
  }));
};

var UserProfessionJob = function UserProfessionJob(_ref) {
  var jwt = _ref.jwt,
      team = _ref.team,
      profession = _ref.profession,
      entityType = _ref.entityType,
      username = _ref.username,
      propName = _ref.name,
      email = _ref.email,
      onChange = _ref.onChange;
  var classes = useStyles();

  var _React$useState = React.useState(''),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      globalError = _React$useState2[0],
      setGlobalError = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      loading = _React$useState4[0],
      setLoading = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      loadingList = _React$useState6[0],
      setLoadingList = _React$useState6[1];

  var _React$useState7 = React.useState(profession),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      profileProfession = _React$useState8[0],
      setProfileProfession = _React$useState8[1];

  var _React$useState9 = React.useState(entityType),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      profileType = _React$useState10[0],
      setProfileType = _React$useState10[1];

  var _React$useState11 = React.useState([]),
      _React$useState12 = _slicedToArray(_React$useState11, 2),
      entityTypes = _React$useState12[0],
      setEntityTypes = _React$useState12[1];

  var _React$useState13 = React.useState(propName),
      _React$useState14 = _slicedToArray(_React$useState13, 2),
      name = _React$useState14[0],
      setName = _React$useState14[1];

  var _React$useState15 = React.useState(false),
      _React$useState16 = _slicedToArray(_React$useState15, 2),
      invalidName = _React$useState16[0],
      setInvalidName = _React$useState16[1];

  var _React$useState17 = React.useState(false),
      _React$useState18 = _slicedToArray(_React$useState17, 2),
      showOtherProfession = _React$useState18[0],
      setShowOtherProfession = _React$useState18[1];

  var _React$useState19 = React.useState(''),
      _React$useState20 = _slicedToArray(_React$useState19, 2),
      otherProfession = _React$useState20[0],
      setOtherProfession = _React$useState20[1];

  var _React$useState21 = React.useState(false),
      _React$useState22 = _slicedToArray(_React$useState21, 2),
      showOtherType = _React$useState22[0],
      setShowOtherType = _React$useState22[1];

  var _React$useState23 = React.useState(''),
      _React$useState24 = _slicedToArray(_React$useState23, 2),
      otherType = _React$useState24[0],
      setOtherType = _React$useState24[1];

  var _React$useState25 = React.useState(''),
      _React$useState26 = _slicedToArray(_React$useState25, 2),
      profileGoal = _React$useState26[0],
      setProfileGoal = _React$useState26[1];

  var _React$useState27 = React.useState(false),
      _React$useState28 = _slicedToArray(_React$useState27, 2),
      surveyCompleted = _React$useState28[0],
      setSurveyCompleted = _React$useState28[1];

  React.useEffect(function () {
    setLoadingList(true);
    types({
      jwt: jwt,
      team: team
    }).then(function (response) {
      setEntityTypes(response);
      setLoadingList(false);
    })["catch"](function () {
      setLoadingList(false);
    });
  }, []);

  var handleProfession = function handleProfession(e, value) {
    setProfileProfession(value && value._id); // setProfileProfession(value.name);
    // if (value.name === 'Other')
    //   setShowOtherProfession(true);
    // else
    //   setShowOtherProfession(false);
  };

  var handleType = function handleType(value) {
    setProfileType(value);
  }; // const handleType = (e, value) => {
  //   setProfileType(value.name);
  //   if (value.name === 'Other')
  //     setShowOtherType(true);
  //   else
  //     setShowOtherType(false);
  // };


  var handleStart = function handleStart() {
    if (name !== '') {
      setInvalidName(false);
      setLoading(true);
      setGlobalError('');
      var _profession = profileProfession;
      var type = profileType;
      if (_profession === 'Other') _profession = otherProfession;
      if (type === 'Other') type = otherType;
      setProfile({
        name: name,
        username: username,
        email: email,
        profession: _profession,
        entityType: type
      }, {
        jwt: jwt,
        team: team
      }).then(function () {
        // setSurveyCompleted(true);
        onChange({
          profession: profileProfession,
          entityType: profileType,
          name: name
        });
        setLoading(false);
      })["catch"](function (e) {
        setLoading(false);
        setGlobalError(translate('error.network'));
      });
    } else {
      setInvalidName(true);
    }
  }; // const handleFinish = () => {
  //   let profession = profileProfession;
  //   let type = profileType
  //   if (profession === 'Other')
  //     profession = otherProfession;
  //   if (type === 'Other')
  //     type = otherType;
  //   onChange({ profession: profession, entityType: type, name, goal: profileGoal });
  // };


  var handleName = function handleName(e) {
    setName(e.target.value);

    if (e.target.value !== '') {
      setInvalidName(false);
    } else {
      setInvalidName(true);
    }
  }; // const handleGoal = (e, value) => {
  //   setProfileGoal(value.name);
  // };
  // const handleOtherProfession = p => {
  //   setOtherProfession(p.target.value);
  // }
  // const handleOtherType = t => {
  //   setOtherType(t.target.value);
  // }
  // const professions = [
  //   { name: 'Student' },
  //   { name: 'Marketer' },
  //   { name: 'Content Creator' },
  //   { name: 'Business Owner' },
  //   { name: 'Freelancer' },
  //   { name: 'Marketing Agency' },
  //   { name: 'Personal Branding' },
  //   { name: 'Other' }
  // ];
  // const types = [
  //   { name: 'Social Media Content' },
  //   { name: 'Ads & Retargeting' },
  //   { name: 'Prospecting & Email marketing' },
  //   { name: 'Product Explanatory videos' },
  //   { name: 'Other' }
  // ];
  // const goals = [
  //   { name: 'Create videos consistently for a brand' },
  //   { name: 'Create videos for a specific project' },
  //   { name: 'Create a single video' }
  // ];


  return __jsx(Box, null, loading && __jsx(Loader, null), !surveyCompleted ? __jsx(React.Fragment, null, __jsx(Box, {
    className: classes.title,
    textAlign: "center"
  }, "Welcome, ", username, __jsx("span", {
    role: "img",
    "aria-label": "Hi",
    style: {
      marginLeft: 8
    }
  }, "\uD83D\uDC4B")), __jsx(Box, {
    className: classes.subTitle
  }, "For the best experience possible, tell us a little about yourself."), globalError && __jsx(ActionAlert, {
    message: globalError,
    className: classes.errorAlert
  }), __jsx(Box, {
    marginBottom: "24px",
    className: classes.autoCompleteContainer
  }, __jsx(Box, {
    className: classes.label
  }, "First Name"), invalidName && __jsx(ActionAlert, {
    icon: __jsx(ReportProblemOutlinedIcon, null),
    variant: "text",
    message: 'The First Name field is required',
    className: classes.formAlert
  }), __jsx("input", {
    invalid: invalidName,
    className: clsx(classes.formInput, invalidName === true ? 'invalid' : null),
    type: "text",
    onChange: handleName,
    placeholder: "Jane"
  })), __jsx(Box, {
    marginBottom: "24px",
    className: classes.autoCompleteContainer
  }, __jsx(Box, {
    className: classes.label
  }, "What's your current role?"), __jsx(RemoteAutoComplete, {
    inputSettings: {
      placeholder: 'Select a role'
    },
    fieldLabel: "name",
    url: "".concat(process.env.API_HOST, "/professions?_sort=order:ASC"),
    headers: {
      Authorization: "Bearer ".concat(jwt),
      'X-Mission-Control-Team': team
    },
    autocompleteSettings: {
      style: {
        width: '100%',
        display: 'inline-flex',
        marginRight: '10px'
      }
    },
    onChange: handleProfession,
    value: profileProfession,
    className: classes.autocomplete
  })), __jsx(Box, {
    marginBottom: "24px"
  }, __jsx(Box, {
    className: classes.label
  }, "What will you be using WOXO for?"), loadingList && __jsx(ListSkeleton, null), !loadingList && __jsx(Grid, {
    container: true,
    className: classes.gridContainer
  }, entityTypes.map(function (item) {
    return __jsx(Grid, {
      key: item._id,
      item: true,
      xs: 6,
      sm: 6,
      lg: 6,
      xl: 6
    }, __jsx(Box, {
      className: clsx(classes.item, item._id === profileType ? 'selected' : ''),
      onClick: function onClick() {
        return handleType(item._id);
      }
    }, item.name));
  }))), __jsx(Box, {
    display: "flex",
    justifyContent: "center"
  }, __jsx(Button, {
    className: classes.startButton,
    disabled: loading || !profileType || !profileProfession || invalidName,
    onClick: handleStart,
    "data-label": "post-signup-survey-completed",
    "data-type": "btn-click"
  }, "Continue"))) : __jsx(React.Fragment, null));
};

UserProfessionJob.defaultProps = {
  profession: null,
  entityType: null,
  onChange: function onChange() {}
};
export default UserProfessionJob;